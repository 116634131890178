<template>
  <div class="page">
    <div class="page-header">
      <!-- 商品标题 -->
      <a-input
        allowClear
        v-model="params.product_name"
        @input="productNameInput"
        size="small"
        style="width: 120px; margin-right: 10px"
        placeholder="标题 或 ID"
      ></a-input>
      <!-- 盒子编号 -->
      <a-input
        allowClear
        v-model="params.code"
        @input="codeInput"
        size="small"
        style="width: 120px; margin-right: 10px"
        placeholder="盒子编号"
      ></a-input>
      <!-- 录入人员 -->
      <a-input
        allowClear
        v-model="params.service_name"
        size="small"
        style="width: 120px; margin-right: 10px"
        placeholder="录入人员"
      ></a-input>
      <!-- 是否白币 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        allowClear
        size="small"
        style="width: 120px; margin: 5px"
        v-model="params.whiteCoinStatus"
        placeholder="白币"
      >
        <a-select-option :value="1">是</a-select-option>
        <a-select-option :value="0">否</a-select-option>
      </a-select>
      <!-- 选择大币种 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="changeVersionlistSearch"
        allowClear
        showSearch
        size="small"
        style="margin: 5px; width: 150px"
        v-model="params.kindId"
        placeholder="请选择大币种"
      >
        <a-select-option
          v-for="items of bigList"
          :key="items.kindId"
          :value="items.kindId"
        >{{ items.kindName }}</a-select-option>
      </a-select>
      <!-- 选择版别 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="selectVersionSearch"
        allowClear
        @search="getVersitionListSearch"
        showSearch
        :filter-option="untils.filterOption"
        size="small"
        style="margin: 5px; width: 120px"
        v-model="params.versionId"
        placeholder="请选择版别"
      >
        <a-select-option
          v-for="items of smallList"
          :key="items.versionId"
          :value="items.versionId"
        >{{ items.versionName }}</a-select-option>
      </a-select>
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="changeGoodsType"
        allowClear
        size="small"
        style="margin: 5px; width: 120px"
        v-model="params.goodsType"
        placeholder="评级公司"
      >
        <a-select-option :value="1">PCGS</a-select-option>
        <a-select-option :value="2">公博</a-select-option>
      </a-select>
      <!-- 选择评分 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="changeScore"
        allowClear
        size="small"
        style="margin: 5px; min-width: 120px"
        v-model="params.scoreId"
        placeholder="评分"
      >
        <a-select-option
          v-for="item of searchRanks"
          :key="item.id"
          :value="item.id"
        >{{ item.scoreName }}</a-select-option>
      </a-select>
      <!-- 选择数据源 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @change="changeSource"
        allowClear
        size="small"
        style="width: 150px; margin: 5px"
        v-model="params.goodsSource"
        placeholder="数据源"
      >
        <a-select-option :value="1">HX</a-select-option>
        <a-select-option :value="3">WPT</a-select-option>
        <a-select-option :value="4">XZ</a-select-option>
        <a-select-option :value="10">SBP</a-select-option>
        <a-select-option :value="2">QBTT</a-select-option>
      </a-select>

      <a-range-picker
        v-model="times"
        :placeholder="['开始时间', '结束时间']"
        size="small"
        style="margin: 5px"
        @change="timeChange"
      />
      <a-select
        :dropdownMatchSelectWidth="false"
        allowClear
        size="small"
        style="width: 150px; margin: 5px"
        v-model="params.appreciationType"
        placeholder="赏析"
      >
        <a-select-option :value="1">有</a-select-option>
        <a-select-option :value="-1">无</a-select-option>
      </a-select>
      <a-button
        @click="search"
        size="small"
        style="margin-left: 10px"
      >搜索</a-button>
    </div>
    <div class="page-body">
      <PageTable
        :type="1"
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @selectChange="onSelectChange"
        @changePage="changePage"
        @priceSortChange="priceSortChange"
        @timeSortChange="timeSortChange"
        @success="getList"
        @delete="getList"
        @edit="editPage"
      ></PageTable>
    </div>
    <EditDollar
      ref="edit"
      type="2"
      :detail="detail"
      v-if="detail"
      @close="detail = ''"
      @success="getList"
    ></EditDollar>
  </div>
</template>

<script>
import PageTable from "@/views/cmsPage/dollarManager/_components/PageTable.vue";
import EditDollar from "@/views/cmsPage/dollarManager/_components/EditDollar.vue";
export default {
  data() {
    return {
      list: [],
      params: {
        pageNum: 1,
        pageSize: 20,
        sort: 0,
        reviewStatus: 1,
      },
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      searchRanks: [],
      notConfirmVersionList: [],
      selctedArrays: [],
      times: [],
      loading: false,
      detail: "",
      bigList: [],
      smallList: [],
    };
  },
  computed: {
    isDisabled() {
      if (this.selctedArrays.length > 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    PageTable,
    EditDollar,
  },

  created() {
    if (this.$route.query.code) {
      this.params.code = this.$route.query.code;
    }
    this.getList();
    this.getBigList();
  },
  mounted() {
    this.$eventBus.$on("searchCode", (code) => {
      this.params = JSON.parse(
        JSON.stringify({
          pageNum: 1,
          pageSize: 20,
          sort: 0,
          reviewStatus: 1,
          code,
        })
      );
      this.getList();
    });
  },
  destroyed() {
    this.$eventBus.$off("searchCode");
  },
  methods: {
    codeInput() {
      if (!this.params.code) {
        delete this.params.code;
      }
    },
    productNameInput() {
      if (!this.params.product_name) {
        delete this.params.product_name;
      }
    },
    // 打开编辑页
    editPage(row) {
      this.detail = JSON.parse(JSON.stringify(row));
    },
    search() {
      this.params.pageNum = 1;
      this.pagination.current = 1;
      this.getList();
    },
    priceSortChange(val) {
      this.params.sort = val;
      delete this.params.timeSort;
      this.search();
    },
    timeSortChange(val) {
      this.params.timeSort = val;
      delete this.params.sort;
      this.search();
    },
    // 切换分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList();
    },
    // 选择版别--小币种
    selectVersionSearch() {
      delete this.params.scoreId;
      this.getScore();
      this.getList();
    },
    // // 获取table选中数据
    onSelectChange(selctedArrays) {
      this.selctedArrays = JSON.parse(JSON.stringify(selctedArrays));
    },
    // 筛选时间
    timeChange(array) {
      if (!array || array.length <= 0) {
        delete this.params.startTime;
        delete this.params.endTime;
      } else {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.params.startTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          } else {
            this.params.endTime = ele._d
              .toLocaleDateString()
              .replace(/\//g, "-");
          }
        });
      }
    },
    // 评级公司
    changeGoodsType(e) {
      this.getList();
    },
    // 选择分数
    changeScore() {
      this.getList();
    },
    // 数据源
    changeSource() {
      // delete this.params.versionId;
      // delete this.params.scoreId;
      this.getList();
    },
    // 大币种
    async changeVersionlistSearch() {
      delete this.params.versionId;
      delete this.params.scoreId;
      this.params.scores = [];
      await this.getVersitionListSearch("");
    },
    // 获取大币种列表
    async getBigList() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverKind"
      );
      if (res.status != 200) return;
      this.bigList = res.data;
    },
    // 搜索版别
    async getVersitionListSearch(str) {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverVersion",
        {
          params: { keyword: str, kindId: this.params.kindId },
        }
      );
      if (res.status == 200) {
        const { data } = res;
        this.smallList = data;
      }
    },
    async getList() {
      this.loading = true;
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/management/list",
        {
          params: this.params,
        }
      );
      this.loading = false;
      if (res.status != 200) return (this.list = []);
      this.list = res.data.records;
      this.pagination.total = res.data.total;
    },
    // 搜索分数
    async getScore() {
      if (!this.params.versionId) return;
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getScreenScoreList",
        {
          params: {
            versionId: this.params.versionId,
          },
        }
      );
      if (res.status != 200) return;
      this.searchRanks = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.page-body {
  flex: 1;
  overflow: auto;
}
</style>